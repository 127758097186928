import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import BlogLink from "./BlogLink"
import { ArrowBack } from "@emotion-icons/boxicons-regular/ArrowBack"

const BackLink = (): JSX.Element => {
  return (
    <Box>
      <BlogLink to="/blog">
        <Flex align="center" color="#707070">
          <Box display="inline-block" mr={2}>
            <ArrowBack size="1.4rem" />
          </Box>
          <Box>back to all</Box>
        </Flex>
      </BlogLink>
    </Box>
  )
}

export default BackLink
